import { useState, useMemo, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import jwtDecode from 'jwt-decode';
import LandingPage from '../components/LandingPage/LandingPage';
import ResponseModal from '../components/ResponseModal/ResponseModal';
import Loader from '../components/Loaders/Loader';
import { completeKYCFlow, getKycDetails } from '../api';

import './Container.scss';
import { defaultUrlType, s3LogoBaseUrl } from '../components/LandingPage/utils';

function LinkKyc() {
  const [loading, setLoading] = useState(true);
  const [identifier, setIdentifier] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [redirectURL, setredirectURL] = useState('');
  const [workflowId, setWorkflowId] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [inputs, setInputs] = useState({});
  const [languages, setLanguages] = useState({});
  const [defaultLanguage, setDefaultLanguage] = useState({});
  const [responseReceived, setResponseReceived] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [urlType, setUrlType] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [appid, setAppid] = useState('');
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const fetchUrlDetails = async () => {
    try {
      const urlIdentifier = queryParams.get('identifier');
      setIdentifier(urlIdentifier);
      const details = await getKycDetails(urlIdentifier);
      setredirectURL(details.redirectUrl);
      setJwtToken(details.jwtToken);
      setWorkflowId(details.workflowId);
      const decodedToken = jwtDecode(details.jwtToken);
      const appId = get(decodedToken, 'appId');
      setAppid(appId);
      setUrlType(get(details, 'inputs.urlType', defaultUrlType));
      setLogoUrl(`${s3LogoBaseUrl}/${appId}/logo.png`);
      setInputs(details.inputs);
      setLanguages(details.languages || { en: 'English' });
      setDefaultLanguage(details.defaultLanguage || 'en');
      const identifierToBeSet = urlIdentifier.split('_');
      identifierToBeSet.shift();
      const txnId = identifierToBeSet.join('_');
      setTransactionId(txnId);
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err);
      }
      setResponseReceived(true);
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.result.error === 'Link expired'
      ) {
        setResponseStatus('invalid');
      } else {
        setResponseStatus('error');
      }
    }
    setLoading(false);
  };

  const callback = async (HyperKycResult) => {
    setResponseReceived(true);
    window.HyperSnapSDK.endUserSession();
    if (
      HyperKycResult.errorCode &&
      HyperKycResult.errorCode === 401 &&
      HyperKycResult.errorMessage === 'Token Expired'
    )
      setResponseStatus('expired');
    else setResponseStatus(HyperKycResult.status);
    if (HyperKycResult.status !== 'error' && HyperKycResult.status !== 'user_cancelled') {
      await completeKYCFlow(identifier);
    }
  };

  const startKYC = async (journeyType) => {
    try {
      localStorage.removeItem('eventType');
      const config = new window.HyperKycConfig(jwtToken, workflowId, transactionId);
      config.setInputs({
        ...(inputs || {}),
        journeyType
      });
      config.setDefaultLangCode(defaultLanguage);
      window.HyperKYCModule.launch(config, callback);
    } catch (err) {
      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(err);
      }
      setResponseReceived(true);
      setResponseStatus('error');
    }
  };

  useEffect(() => {
    fetchUrlDetails();
  }, []);

  const getContainerContent = () => {
    if (loading) {
      return (
        <div id="kyc_container">
          <Loader />;
        </div>
      );
    }
    if (responseReceived && responseStatus !== 'user_cancelled') {
      return (
        <div id="kyc_container">
          <ResponseModal
            redirectURL={redirectURL}
            status={responseStatus}
            transactionId={transactionId}
            appId={appid}
          />
        </div>
      );
    }
    return (
      <LandingPage
        onCardClick={startKYC}
        languages={languages}
        defaultLanguage={defaultLanguage}
        updateLanguage={(language) => setDefaultLanguage(language)}
        urlType={urlType}
        logoUrl={logoUrl}
      />
    );
  };

  return getContainerContent();
}

export default LinkKyc;
