import SearchCKYCLogo from '../../assests/images/searchCKYC.png';
import IdCardLogo from '../../assests/images/idCard.png';
import manualKYCLogo from '../../assests/images/manualKYC.png';

export const defaultUrlType = 'W';

export const s3LogoBaseUrl = process.env.REACT_APP_S3_LOGO_URL;

const landingButtonList = [
  {
    id: 'ckyc',
    title: 'Search Existing CKYC',
    description: 'Share document numbers like PAN, Voter ID number, Driving License number etc.',
    image: SearchCKYCLogo,
    journeyType: 'CKYC',
    showWhenUrlTypesAre: ['W']
  },
  {
    id: 'aadhaar_kyc',
    title: 'Instant Aadhaar based KYC',
    description: 'Mobile number linked with aadhaar required',
    image: IdCardLogo,
    journeyType: 'INSTANT_AADHAAR',
    showWhenUrlTypesAre: ['P', 'W']
  },
  {
    id: 'manual_kyc',
    title: 'Manual KYC',
    description: 'Upload Documents like Voter ID or Driving License or Passport etc.',
    image: manualKYCLogo,
    journeyType: 'MANUAL',
    showWhenUrlTypesAre: ['P', 'W']
  }
];

export default landingButtonList;
