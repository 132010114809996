/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

import './ResponseModal.scss';

function Redirect({ url, transactionId, status, appId }) {
  const countdownTimeInMs = 4000;
  let redirectUrl;
  if (appId === 'ezs12s' || appId === 'qw9rjg') {
    redirectUrl = `${url}?transactionId=${transactionId}&status=${status}`;
  } else {
    redirectUrl = new URL(url);
    redirectUrl.searchParams.append('transactionId', transactionId);
    redirectUrl.searchParams.append('status', status);
  }

  return (
    <p id="redirect__msg">
      redirecting in{' '}
      <Countdown
        date={Date.now() + countdownTimeInMs}
        onTick={(time) => {
          if (time.seconds === 2) window.location = redirectUrl;
        }}
        renderer={(time) => (
          <span>{time.seconds > 2 ? `${time.seconds - 1} seconds` : '1 second'}</span>
        )}
      />
    </p>
  );
}

export default Redirect;

Redirect.propTypes = {
  url: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired
};
