import React from 'react';
import PropTypes from 'prop-types';
import { statusMsg, translateApplicationStatus } from '../../utils/applicationStatus';
import Animations from './Animations';
import Redirect from './Redirect';
import Footer from './Footer';

import './ResponseModal.scss';

function ResponseModal({ status, redirectURL, transactionId, appId }) {
  return (
    <div id="response_modal__container">
      <div id="response_modal__sub_container">
        <div>
          <p id="response_modal__heading">
            {statusMsg[translateApplicationStatus[status]].heading}
          </p>
          <p id="response_modal__subText">
            {statusMsg[translateApplicationStatus[status]].subText}
          </p>
        </div>
        <Animations status={status} />
        {redirectURL ? (
          <Redirect
            url={redirectURL}
            transactionId={transactionId}
            status={status === 'expired' ? 'error' : status}
            appId={appId}
          />
        ) : (
          <p id="response_modal__redirect">Process completed. You can close the tab</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ResponseModal;

ResponseModal.propTypes = {
  status: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired
};
