import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import Success from '../../assests/animations/success.json';
import Failure from '../../assests/animations/failure.json';
import Processing from '../../assests/animations/processing.json';
import ErrorState from '../../assests/animations/somethingWrong.json';

import './ResponseModal.scss';

function Animations({ status }) {
  const getAnimation = () => {
    if (status === 'auto_approved') return Success;
    if (status === 'needs_review') return Processing;
    if (status === 'error') return ErrorState;
    return Failure;
  };

  return <Lottie id="animation" animationData={getAnimation()} loop={false} />;
}

export default Animations;

Animations.propTypes = {
  status: PropTypes.string.isRequired
};
