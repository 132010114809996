import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import './loaders.scss';

function Loader() {
  return (
    <div id="loader">
      <CircularProgress
        variant="determinate"
        sx={{
          color: 'rgba(85, 78, 241, 0.2)',
          position: 'relative',
          left: 20
        }}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: '#554ef1',
          position: 'relative',
          left: -20
        }}
      />
    </div>
  );
}

export default Loader;
