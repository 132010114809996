import PropTypes from 'prop-types';
import ButtonCard from './ButtonCard';
import landingButtonList from './utils';
import VerifiedImage from '../../assests/images/verified.jpg';

import './LandingPage.scss';

function LandingPage({ onCardClick, urlType, logoUrl }) {
  const filteredLandingButtons = landingButtonList.filter(({ showWhenUrlTypesAre }) =>
    showWhenUrlTypesAre.includes(urlType)
  );

  return (
    <div id="landing_page__container">
      <div id="landing_page__header">
        <img src={logoUrl} alt="" />
      </div>
      <div id="landing_page__content">
        <div id="landing_page__left">
          <div id="landing_page__left__header">
            <h1>KYC has been made easier</h1>
            <h3>Choose our digital-friendly options</h3>
          </div>
          <div id="landing_page__left__content">
            <p>
              By verifying ID cards and faces, confirming addresses by utility bills, KYC secures
              customer&apos;s account{' '}
            </p>
          </div>
          <div id="landing_page__left__buttons">
            {filteredLandingButtons.map(({ id, title, description, image, journeyType }) => (
              <ButtonCard
                key={id}
                title={title}
                description={description}
                image={image}
                journeyType={journeyType}
                onCardClick={onCardClick}
              />
            ))}
          </div>
        </div>
        <div id="landing_page__right">
          <div id="landing_page__right__image">
            <img src={VerifiedImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

LandingPage.propTypes = {
  onCardClick: PropTypes.func.isRequired,
  urlType: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired
};

export default LandingPage;
