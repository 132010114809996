export const statusMsg = {
  APPROVED: {
    heading: 'KYC successful!',
    subText: 'Your KYC has been verified successfully. Thank you for your cooperation'
  },
  UNDERREVIEW: {
    heading: 'KYC completed!',
    subText:
      'Your KYC process has been completed and your details are being processed. Thank you for your cooperation'
  },
  REJECTED: {
    heading: 'KYC completed!',
    subText:
      'Your KYC process has been completed and your details are being processed. Thank you for your cooperation'
  },
  ERROR: {
    heading: 'Something Went Wrong',
    subText: 'Please reach out to our support team and resolve this.'
  },
  INVALID: {
    heading: 'Invalid Link',
    subText: 'Please reach out to our support team and resolve this'
  },
  EXPIRED: {
    heading: 'Link Expired',
    subText: 'Please reach out to our support team and resolve this.'
  }
};

export const translateApplicationStatus = {
  auto_approved: 'APPROVED',
  auto_declined: 'REJECTED',
  needs_review: 'UNDERREVIEW',
  user_cancelled: 'REJECTED',
  error: 'ERROR',
  invalid: 'INVALID',
  expired: 'EXPIRED'
};
