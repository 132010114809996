import PropTypes from 'prop-types';

import './LandingPage.scss';

function ButtonCard({ title, description, image, journeyType, onCardClick }) {
  const handleClick = () => {
    onCardClick(journeyType);
  };

  return (
    <div id="button_card__container" onClick={handleClick} aria-hidden>
      <img src={image} alt="" />
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

ButtonCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  journeyType: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired
};

export default ButtonCard;
