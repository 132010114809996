import axios from 'axios';

export const getKycDetails = async (identifier) => {
  const apiResponse = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/details`,
    headers: { identifier }
  });
  return apiResponse.data.result;
};

export const completeKYCFlow = async (identifier) => {
  const apiResponse = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/complete`,
    headers: { identifier }
  });
  return apiResponse.data.result;
};
